import { createContext, useState } from 'react';
import api from 'services/api';

export const DocumentosContext = createContext();

export const DocumentosProvider = ({ children }) => {
  const [documento, setDocumento] = useState(null);
  const [documentoItem, setDocumentoItem] = useState(null);
  const [documentoOrigens, setDocumentoOrigens] = useState([]);
  const [documentoVinculos, setDocumentoVinculos] = useState([]);
  const [documentoImport, setDocumentoImport] = useState([]);
  const [getLoading, setGetLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const getDocumento = async (id) => {
    try {
      setGetLoading(true);
      setDocumento(null);
      const { data } = await api.get(`/Cadastros/Documento/${id}`, {
        params: {
          Itens: true,
          Registros: true,
          Saida: true,
          Entrada: true,
          Moedas: true,
          Financeiro: true,
          Transportes: true,
          NFe: true,
          Cce: true,
          Totais: true,
          Exportacao: true,
          Importacao: true,
          Contrato: true,
          MDFe: true,
        },
      });
      setDocumento(data);
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const postDocumento = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      const { data: res } = await api.post('/Cadastros/Documento', data);
      if (cb) {
        cb();
      }
      if (res?.url) {
        window.open(res?.url);
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const postDocumentos = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post('/Cadastros/Documento/Multiple', data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const postDocumentosGenerator = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post('/Cadastros/Documento/Generator', data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const deleteDocumento = async ({ id, cb }) => {
    try {
      setDeleteLoading(true);
      await api.delete(`/Cadastros/Documento/${id}`);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setDeleteLoading(false);
    }
  };

  const postAnexo = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post('/Cadastros/Documento/Registro/Anexo', data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const deleteAnexo = async ({ id, cb }) => {
    try {
      setDeleteLoading(true);
      await api.delete(`/Cadastros/Documento/Registro/Anexo/${id}`);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setDeleteLoading(false);
    }
  };

  const getDocumentoItem = async (id) => {
    try {
      setGetLoading(true);
      setDocumentoItem(null);
      const { data } = await api.get(`/Cadastros/Documento/Item/${id}`, {
        params: {
          Estrutura: true,
          Processo: true,
          Imposto: true,
          Mc: true,
          Vinculo: true,
          Variacao: true,
          Movimentos: true,
        },
      });
      setDocumentoItem(data);
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const getDocumentoItens = async ({ id, isItem, cb }) => {
    try {
      setGetLoading(true);
      const { data } = await api.get(`/Cadastros/Documento/Itens/${id}`, {
        params: { isItem, Estrutura: true },
      });
      if (cb) {
        cb(data);
      }
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const postDocumentoItemEstrutura = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post('/Cadastros/Documento/Item/EstruturaReset', data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const postDocumentoItemProcesso = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post('/Cadastros/Documento/Item/ProcessoReset', data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const postDocumentoItemImposto = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post('/Cadastros/Documento/Item/ImpostoReset', data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const postDocumentoItemCfo = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post('/Cadastros/Documento/Item/CfoReset', data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const getDocumentoOrigens = async ({ params, cb = null }) => {
    try {
      setGetLoading(true);
      setDocumentoOrigens([]);
      const { data } = await api.get(`/Cadastros/Documento/Origens`, {
        params,
      });
      setDocumentoOrigens(data);
      if (cb) {
        cb(data);
      }
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const getDocumentoVinculos = async ({ params, cb }) => {
    try {
      setGetLoading(true);
      setDocumentoVinculos([]);
      const { data } = await api.get(`/Cadastros/Documento/Vinculos`, {
        params,
      });
      if (cb) {
        cb(data);
      }
      setDocumentoVinculos(data);
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const getDocumentoImportacao = async (params) => {
    try {
      setGetLoading(true);
      setDocumentoImport([]);
      const { data } = await api.get(`/Cadastros/Documento/Importacao`, {
        params,
      });
      setDocumentoImport(data);
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const postEncerramento = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post('/MDFe/Encerramento', data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const deleteDocumentoItemVinculo = async ({ params, cb = null }) => {
    try {
      setDeleteLoading(true);
      await api.delete(`/Cadastros/Documento/Item/Vinculo`, {
        params,
      });
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setDeleteLoading(false);
    }
  };

  const getExpedicao = async ({ params, cb = null }) => {
    try {
      setGetLoading(true);
      const { data } = await api.get(`/Suprimentos/Expedicoes/Itens`, {
        params,
      });
      if (cb) {
        cb(data);
      }
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  return (
    <DocumentosContext.Provider
      value={{
        documento,
        getLoading,
        getDocumento,
        postLoading,
        postDocumento,
        postDocumentos,
        postDocumentosGenerator,
        deleteDocumento,
        deleteLoading,
        deleteAnexo,
        postAnexo,
        documentoItem,
        getDocumentoItem,
        getDocumentoItens,
        postDocumentoItemEstrutura,
        postDocumentoItemProcesso,
        postDocumentoItemImposto,
        postDocumentoItemCfo,
        getDocumentoOrigens,
        documentoOrigens,
        getDocumentoVinculos,
        documentoVinculos,
        getDocumentoImportacao,
        documentoImport,
        postEncerramento,
        deleteDocumentoItemVinculo,
        getExpedicao,
      }}
    >
      {children}
    </DocumentosContext.Provider>
  );
};
